


















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Slider extends Vue {
  @Prop() slider!: any;
}
