










































































































import { Component, Vue } from "vue-property-decorator";
import SliderX from "../components/ui/sliderX/Slider.vue";
import SliderXTime from "../components/ui/sliderXTime/Slider.vue";
import SliderXTemp from "../components/ui/sliderXTemp/Slider.vue";

import DBSlider from "../components/ui/sliderX/slider.js";
import DBSliderTime from "../components/ui/sliderXTime/slider.js";
import DBSliderTemp from "../components/ui/sliderXTemp/slider.js";

import Loader from "../components/ui/Loader.vue";
import NoData from "../components/ui/No-Data.vue";

@Component({
  components: {
    SliderX,
    SliderXTime,
    SliderXTemp,

    Loader,
    NoData,
  },
})
export default class Settings extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  mounted() {
    this.fetchSettingsList();
  }

  accelSliders = [
    {
      id: 0,
      bgColor: "#edddd4",
      leftColor: "#2a9d8f",
      rightColor: "#e76f51",
      label: "aX",
      minValue: 0,
      maxValue: 100,
    },
    {
      id: 1,
      bgColor: "#edddd4",
      leftColor: "#2a9d8f",
      rightColor: "#e76f51",
      label: "aY",
      minValue: 0,
      maxValue: 100,
    },
    {
      id: 2,
      bgColor: "#edddd4",
      leftColor: "#2a9d8f",
      rightColor: "#e76f51",
      label: "aZ",
      minValue: 0,
      maxValue: 100,
    },
  ];

  gyroSliders = [
    {
      id: 3,
      bgColor: "#edddd4",
      leftColor: "#ef476f",
      rightColor: "#118ab2",
      label: "gX",
      minValue: 0,
      maxValue: 100,
    },
    {
      id: 4,
      bgColor: "#edddd4",
      leftColor: "#ef476f",
      rightColor: "#118ab2",
      label: "gY",
      minValue: 0,
      maxValue: 100,
    },
    {
      id: 5,
      bgColor: "#edddd4",
      leftColor: "#ef476f",
      rightColor: "#118ab2",
      label: "gZ",
      minValue: 0,
      maxValue: 100,
    },
  ];

  tempSlider = [
    {
      id: 6,
      bgColor: "#edddd4",
      leftColor: "#e29578",
      rightColor: "#006d77",
      label: "temp °C",
      minValue: 0,
      maxValue: 100,
    },
  ];

  tagInOutSlider = [
    {
      id: 7,
      bgColor: "#edddd4",
      leftColor: "#ef476f",
      rightColor: "#118ab2",
      label: "Hours",
      minValue: 0,
      maxValue: 100,
    },
  ];

  initSliders(data: any) {
    setTimeout(() => {
      this.accelSliders.map(
        (x: any) => new DBSlider(document.getElementById("svg_v" + x.id), "y")
      );

      this.gyroSliders.map(
        (x: any) => new DBSlider(document.getElementById("svg_v" + x.id), "y")
      );

      this.tempSlider.map(
        (x: any) =>
          new DBSliderTemp(document.getElementById("svg_v" + x.id), "y")
      );

      this.tagInOutSlider.map(
        (x: any) =>
          new DBSliderTime(document.getElementById("svg_v" + x.id), "y")
      );
    }, 10);

    this.accelSliders[0].minValue = parseFloat(data.min_aX);
    this.accelSliders[0].maxValue = parseFloat(data.max_aX);

    this.accelSliders[1].minValue = parseFloat(data.min_aY);
    this.accelSliders[1].maxValue = parseFloat(data.max_aY);

    this.accelSliders[2].minValue = parseFloat(data.min_aZ);
    this.accelSliders[2].maxValue = parseFloat(data.max_aZ);

    this.gyroSliders[0].minValue = parseFloat(data.min_gX);
    this.gyroSliders[0].maxValue = parseFloat(data.max_gX);

    this.gyroSliders[1].minValue = parseFloat(data.min_gY);
    this.gyroSliders[1].maxValue = parseFloat(data.max_gY);

    this.gyroSliders[2].minValue = parseFloat(data.min_gZ);
    this.gyroSliders[2].maxValue = parseFloat(data.max_gZ);

    this.tempSlider[0].minValue = parseFloat(data.min_temp);
    this.tempSlider[0].maxValue = parseFloat(data.max_temp);

    this.tagInOutSlider[0].minValue = parseFloat(data.min_tag);
    this.tagInOutSlider[0].maxValue = parseFloat(data.max_tag);
  }

  reloadData() {
    this.isLoading = true;
    this.showNoData = false;
    this.fetchSettingsList();
  }

  isLoading = false;
  showNoData = false;
  basicDetailData = "";
  async fetchSettingsList() {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/thresold`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.basicDetailData = data;
        this.initSliders(data);
      } else {
        this.basicDetailData = "";
        this.showNoData = true;
      }
    } catch (e) {
      this.basicDetailData = "";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }

  isDataUploading = false;
  async submitSettingsData() {
    try {
      this.isDataUploading = true;

      const data = {
        min_aX: this.getLeftSliderDataFromSvgId("0"),
        max_aX: this.getRightSliderDataFromSvgId("0"),

        min_aY: this.getLeftSliderDataFromSvgId("1"),
        max_aY: this.getRightSliderDataFromSvgId("1"),

        min_aZ: this.getLeftSliderDataFromSvgId("2"),
        max_aZ: this.getRightSliderDataFromSvgId("2"),

        min_gX: this.getLeftSliderDataFromSvgId("3"),
        max_gX: this.getRightSliderDataFromSvgId("3"),

        min_gY: this.getLeftSliderDataFromSvgId("4"),
        max_gY: this.getRightSliderDataFromSvgId("4"),

        min_gZ: this.getLeftSliderDataFromSvgId("5"),
        max_gZ: this.getRightSliderDataFromSvgId("5"),

        min_temp: this.getLeftSliderDataFromSvgId("6"),
        max_temp: this.getRightSliderDataFromSvgId("6"),

        min_tag: this.getLeftSliderDataFromSvgId("7"),
        max_tag: this.getRightSliderDataFromSvgId("7"),
      };

      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/thresold`,
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      if (response.status == 200) {
        this.$store.commit("showToastMessage", {
          boldText: "Success!",
          text: "Settings added successfully",
          type: "Success",
        });
        this.reloadData();
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isDataUploading = false;
    }
  }

  isDefaultDataUploading = false;
  async submitDefaultSettingsData() {
    this.isDefaultDataUploading = true;
    const data = JSON.stringify({
      min_aX: " -0.6 ",
      max_aX: " -0.4 ",
      min_aY: " -0.1 ",
      max_aY: " 0.2 ",
      min_aZ: " 9.2 ",
      max_aZ: " 9.9 ",
      min_gX: " -0.3 ",
      max_gX: " 0 ",
      min_gY: " -0.4 ",
      max_gY: " 0.1 ",
      min_gZ: " -0.1 ",
      max_gZ: " 0.1 ",
      min_temp: " 10 ",
      max_temp: " 52 ",
      min_tag: " 0 ",
      max_tag: " 10 "
    });

    await fetch(`${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/thresold`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: data
    });

    this.reloadData();
    this.isDefaultDataUploading = false;
  }

  getLeftSliderDataFromSvgId(id: string) {
    const data: any = window.document.querySelector("#svg_v" + id)
      ? window.document.querySelector("#svg_v" + id)?.querySelector(".left")
        ? window.document
            .querySelector("#svg_v" + id)
            ?.querySelector(".left")
            ?.querySelector("text")?.textContent
        : 0
      : 0;

    return data;
  }

  getRightSliderDataFromSvgId(id: string) {
    const data: any = window.document.querySelector("#svg_v" + id)
      ? window.document.querySelector("#svg_v" + id)?.querySelector(".right")
        ? window.document
            .querySelector("#svg_v" + id)
            ?.querySelector(".right")
            ?.querySelector("text")?.textContent
        : 0
      : 0;

    return data;
  }
}
